import Dropdown from "@/components/Common/Inputs/Dropdown/Dropdown";
import InputField from "@/components/Common/Inputs/Input/Input";
import Button from "@/components/Common/Buttons/Button/Button";
import HeartIcon from "@/components/Common/Icons/HeartIcon/";
import LabelNew from "@/components/Common/Labels/LabelNew/LabelNew";
import DashboardPropertyStatsListItem
    from "@/views/AdminDashboard/AdminDashboardProperty/DashboardPropertyStatsListItem/DashboardPropertyStatsListItem/";

export default {
    name: 'DashboardPropertyStats',
    props: {
        type: {
            type: String,
            default: ''
        },
        name: {
            type: String,
            default: ''
        },
        orderamount: {
            type: String,
            default: ''
        },
        date: {
            type: String,
            default: ''
        },
        phone: {
            type: String,
            default: ''
        },
        title: {
            type: String,
            default: ''
        },
        orderstatus: {
            type: String,
            default: ''
        },

    },
    components: {
        Dropdown,
        InputField,
        Button,
        HeartIcon,
        LabelNew,
        DashboardPropertyStatsListItem

    },
    mounted() {
    },
    data() {
        return {
            properties: [
                {
                    adnumber: '1',
                    time: '20:29',
                    propertyid: 'G20222',
                    title: 'Тристаен апартамент в Лозенец',
                    views: 244,
                    date: '22.03.2022'

                },
                {
                    adnumber: '2',
                    propertyid: 'G20222',
                    title: 'Тристаен апартамент в Лозенец',
                    views: 2424,
                    date: '22.03.2022'

                },
                {
                    adnumber: '3',
                    propertyid: 'G20342',
                    title: 'Двустаен в Манастриски ливади',
                    views: 4324,
                    date: '22.03.2022'
                },
                {
                    adnumber: '4',
                    propertyid: 'G235342',
                    title: 'Студио до Централен Софийски Затвор',
                    views: 5564,
                    date: '22.03.2022'
                },
                {
                    adnumber: '5',
                    propertyid: 'G20222',
                    title: 'Тристаен апартамент в Лозенец',
                    views: 244,
                    date: '22.03.2022'
                },
                {
                    adnumber: '6',
                    propertyid: 'G20222',
                    title: 'Тристаен апартамент в Лозенец',
                    views: 2424,
                    date: '22.03.2022'

                },


            ],
            translation: {
                property: 'Имот',
                views: 'Преглеждания',
                inquiries: 'Заявки',
                most_inquiries: 'Имоти с най-много заявки за огледи',
            }
        }
    }
}