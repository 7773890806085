import Dropdown from "@/components/Common/Inputs/Dropdown/Dropdown";
import InputField from "@/components/Common/Inputs/Input/Input";
import Button from "@/components/Common/Buttons/Button/Button";
import HeartIcon from "@/components/Common/Icons/HeartIcon/";
import LabelNew from "@/components/Common/Labels/LabelNew/LabelNew";
import PreviewButton from "@/components/Common/Buttons/PreviewButton/PreviewButton";

export default {
    name: 'DashboardPropertyStatsListItem',
    props: {
        adnumber: {
            type: String,
            default: ''
        },
        propertyid: {
            type: String,
            default: ''
        },
        title: {
            type: String,
            default: ''
        },
        date: {
            type: String,
            default: ''
        },
        views: {
            type: String,
            default: ''
        },


    },
    components: {
        Dropdown,
        InputField,
        Button,
        HeartIcon,
        LabelNew,
        PreviewButton

    },
    mounted() {
    },
    data() {
        return {
            properties: [
                {
                    adnumber: '1',
                    date: '21.11.2022',
                    time: '20:29',
                    propertyid: 'G20222',
                    title: 'Тристаен апартамент в Лозенец',

                },
                {
                    adnumber: '2',
                    date: '21.11.2022',
                    propertyid: 'G20222',
                    title: 'Тристаен апартамент в Лозенец',

                },
                {
                    adnumber: '3',
                    date: '11.12.2022',
                    propertyid: 'G20342',
                    title: 'Двустаен в Манастриски ливади',

                },
                {
                    adnumber: '4',
                    date: '21.10.2022',
                    propertyid: 'G235342',
                    title: 'Студио до Централен Софийски Затвор',

                },


            ],
            translation: {
                property: 'Имот',
                views: 'Преглеждания',
            }
        }
    }
}