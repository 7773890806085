<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<template>
  <div>
    <div class="property-stats-wrapper">
      <div class="d-flex align-items-center" v-if="type === 'views'">
        <div>
          <svg width="15" height="11" viewBox="0 0 15 11" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M7.61629 10.0035C6.47176 9.99733 5.49744 9.72691 4.57594 9.25983C3.15554 8.54077 1.95819 7.50828 0.895825 6.29755C0.643441 6.01485 0.402795 5.70756 0.168019 5.40027C-0.0550183 5.11141 -0.0550183 4.89016 0.16215 4.60131C1.22451 3.20007 2.45122 1.99549 3.93618 1.09205C4.82832 0.551222 5.77917 0.164036 6.81218 0.0411201C7.95085 -0.0940877 9.03669 0.108724 10.0814 0.581951C11.4314 1.19039 12.5877 2.09382 13.6148 3.17548C14.0433 3.63027 14.4365 4.12194 14.8298 4.60131C15.0587 4.88402 15.0528 5.11141 14.8357 5.40027C13.7792 6.79536 12.5525 7.99994 11.0734 8.90338C10.1812 9.45035 9.23038 9.83139 8.19736 9.96046C7.97432 9.99118 7.73955 9.99733 7.61629 10.0035ZM1.10712 4.98235C1.59428 5.58464 2.08731 6.13162 2.62143 6.62328C3.54879 7.47755 4.55833 8.20275 5.73808 8.63911C6.46002 8.90338 7.20543 9.02629 7.96846 8.9464C8.96625 8.84192 9.87014 8.45473 10.7271 7.92005C11.8188 7.23172 12.7462 6.35287 13.5913 5.36339C13.6911 5.24662 13.785 5.1237 13.8789 5.00693C13.8496 4.96391 13.8261 4.93318 13.8085 4.9086C13.6324 4.70579 13.4622 4.49683 13.2803 4.29402C12.259 3.15705 11.1145 2.18601 9.74102 1.55913C9.14234 1.28257 8.51431 1.0982 7.85694 1.05518C6.75936 0.975283 5.74395 1.28872 4.78137 1.81111C3.87161 2.28434 3.07337 2.91736 2.34557 3.64256C1.92297 4.06662 1.52385 4.52756 1.10712 4.98235Z"
                fill="#FF7A00"/>
            <path
                d="M10.463 5.00697C10.463 6.72165 9.1306 8.11061 7.49303 8.10446C5.8496 8.09832 4.52899 6.70936 4.53486 4.99468C4.54659 3.27386 5.86134 1.90334 7.4989 1.90334C9.14234 1.89719 10.4688 3.28615 10.463 5.00697ZM7.50477 2.92969C6.4248 2.9174 5.54439 3.83312 5.52091 4.98239C5.49744 6.11322 6.38959 7.05353 7.49303 7.06582C8.573 7.07811 9.45928 6.16239 9.47689 5.01312C9.4945 3.88229 8.60822 2.94198 7.50477 2.92969Z"
                fill="#FF7A00"/>
          </svg>

        </div>
        <div class="stats-title">
          {{ $t('most-viewed-properties') }}
        </div>
      </div>
      <div class="d-flex align-items-center " v-if="type === 'inquiries'">
        <div>
          <svg width="15" height="17" viewBox="0 0 15 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.81958 6.30383H14.1875" stroke="#FF7A00" stroke-width="1.125" stroke-linecap="round"
                  stroke-linejoin="round"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M3.60608 9.23242C3.60608 8.92176 3.85792 8.66992 4.16858 8.66992H4.17553C4.48619 8.66992 4.73803 8.92176 4.73803 9.23242C4.73803 9.54308 4.48619 9.79492 4.17553 9.79492H4.16858C3.85792 9.79492 3.60608 9.54308 3.60608 9.23242ZM7.50371 8.66992C7.19305 8.66992 6.94121 8.92176 6.94121 9.23242C6.94121 9.54308 7.19305 9.79492 7.50371 9.79492H7.51066C7.82132 9.79492 8.07316 9.54308 8.07316 9.23242C8.07316 8.92176 7.82132 8.66992 7.51066 8.66992H7.50371ZM10.8318 8.66992C10.5212 8.66992 10.2693 8.92176 10.2693 9.23242C10.2693 9.54308 10.5212 9.79492 10.8318 9.79492H10.8388C11.1494 9.79492 11.4013 9.54308 11.4013 9.23242C11.4013 8.92176 11.1494 8.66992 10.8388 8.66992H10.8318ZM10.8318 11.5846C10.5212 11.5846 10.2693 11.8364 10.2693 12.1471C10.2693 12.4578 10.5212 12.7096 10.8318 12.7096H10.8388C11.1494 12.7096 11.4013 12.4578 11.4013 12.1471C11.4013 11.8364 11.1494 11.5846 10.8388 11.5846H10.8318ZM6.94121 12.1471C6.94121 11.8364 7.19305 11.5846 7.50371 11.5846H7.51066C7.82132 11.5846 8.07316 11.8364 8.07316 12.1471C8.07316 12.4578 7.82132 12.7096 7.51066 12.7096H7.50371C7.19305 12.7096 6.94121 12.4578 6.94121 12.1471ZM4.16858 11.5846C3.85792 11.5846 3.60608 11.8364 3.60608 12.1471C3.60608 12.4578 3.85792 12.7096 4.16858 12.7096H4.17553C4.48619 12.7096 4.73803 12.4578 4.73803 12.1471C4.73803 11.8364 4.48619 11.5846 4.17553 11.5846H4.16858Z"
                  fill="#FF7A00"/>
            <path d="M10.5328 0.75V3.21809" stroke="#FF7A00" stroke-width="1.125" stroke-linecap="round"
                  stroke-linejoin="round"/>
            <path d="M4.47423 0.75V3.21809" stroke="#FF7A00" stroke-width="1.125" stroke-linecap="round"
                  stroke-linejoin="round"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M10.6787 1.93457H4.32822C2.1257 1.93457 0.75 3.16152 0.75 5.41684V12.2041C0.75 14.4949 2.1257 15.7502 4.32822 15.7502H10.6718C12.8812 15.7502 14.25 14.5161 14.25 12.2608V5.41684C14.2569 3.16152 12.8882 1.93457 10.6787 1.93457Z"
                  stroke="#FF7A00" stroke-width="1.125" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>


        </div>
        <div class="stats-title">
          {{ $t('most-viewing-requests') }}
        </div>
      </div>
      <div class="row top-table-row g-0 d-none d-md-flex">
        <div class="col-1"> #</div>
        <div class="col-2"> {{ $t('offer-id') }}</div>
        <div class="col-7"> {{ $t('property') }}</div>
        <div class="col-2" v-if="type === 'views'"> {{ $t('offer-views') }}</div>
        <div class="col-2  text-center" v-if="type === 'inquiries'"> {{ $t('offer-inquiries') }}</div>
      </div>
      <div class="row ranking-table-row" v-for="property in properties">
        <DashboardPropertyStatsListItem
            :adnumber="property.adnumber"
            :title="property.title"
            :propertyid="property.propertyid"
            :views="property.views"
            :date="property.date"
        />
      </div>
    </div>


  </div>
</template>
