import DashboardPropertyStats
    from "@/views/AdminDashboard/AdminDashboardProperty/DashboardPropertyStats/DashboardPropertyStats/";

export default {
    name: 'AdminDashboardProperty',
    components: {
        DashboardPropertyStats
    },
    data() {
        return {
            filterBoxActive: false,

            statistics: {
                offers_count: 242,
                views_count: 1043,
                viewing_requests_count: 24,
            },
            sortingOptions: [
                {
                    title: this.$t('last-week'),
                    value: 'last_week',
                },
                {
                    title: this.$t('last-month'),
                    value: 'last_month',
                },
                {
                    title: this.$t('last-quarter'),
                    value: 'last_quarter',
                },
                {
                    title: this.$t('last-year'),
                    value: 'last_year',
                },
            ],
            selectedOption: {
                title: this.$t('choose-period'),
                value: '',
            },

            //charts
            viewsChartOptions: {
                chart: {
                    id: 'vuechart-example'
                },
                xaxis: {
                    categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug']
                }
            },
            viewsChartData: [{
                name: 'series-1',
                data: [30, 40, 45, 50, 49, 60, 70, 91]
            }]
        }
    },
    methods: {
        toggleFilterBox() {
            this.filterBoxActive = !this.filterBoxActive
        },
        selectOption(option) {
            this.toggleFilterBox();
            this.selectedOption = option;
        }
    },
}
